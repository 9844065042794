<template>
  <div class="box22 container" style="background-color: #fff;">
    <el-drawer
      :before-close="clearDrawerContent"
      :visible.sync="drawer"
      size="80%"
      :with-header="false"
      :destroy-on-close="true"
      :close-on-press-escape="false"
      :wrapperClosable="false"
    >
      <!-- <templateTitle title="人员申请列表"></templateTitle> -->
      <!-- 搜索筛选 -->
      <div class="item">
        <div class="flex-between" style="align-items: center;">
          <templateTitle title="权限管理" :isBack="false"></templateTitle>
          <!-- <el-button size="mini" type="primary" plain icon="el-icon-search" @click="logCheckedArr">logSelect</el-button> -->
          <el-button type="text"><i class="el-icon-close closebtn" @click="btnClose"></i></el-button>
        </div>
      </div>
      <div class="table-padding">
        <el-table
          :data="newTabelData"
          :span-method="deviceSpanMethod"
          ref="table"
          stripe
          border
          style="width: 100%"
          highlight-current-row
          v-loading="loading"
          :header-cell-style="{ color: '#212A33', backgroundColor: '#fafafa' }"
          height="800"
        >
          <el-table-column prop="firstName" label="目录" width="280"> </el-table-column>

          <!-- <el-table-column label="目录权限" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.secondMenuArr.length">
              <el-checkbox v-model="checked">PC端权限</el-checkbox>
              <el-checkbox v-model="checked">APP端权限</el-checkbox>
            </span>
          </template>
        </el-table-column> -->

          <el-table-column prop="secondName" label="菜单" width="280">
            <template slot-scope="scope">
              <span
                ><el-checkbox-group v-model="checkedBtnArr">
                  <el-checkbox v-for="(item, index) in scope.row.secondMenuArr" :label="item.id" :key="item.id">{{
                    item.title
                  }}</el-checkbox>
                </el-checkbox-group></span
              >
            </template>
          </el-table-column>

          <el-table-column label="PC端权限" width="100">
            <template slot="header" slot-scope="scope">
              <el-checkbox
                :indeterminate="isPCIndeterminate"
                v-model="isPCCheckAll"
                @change="handleCheckAllChangeisPC($event, scope.row)"
                >全选</el-checkbox
              >
            </template>
            <template slot-scope="scope">
              <!-- <span v-if="scope.row.secondMenuArr.length">
                <el-checkbox
                  v-model="scope.row.secondMenuArr[0].isPC"
                  @change="handleCheckedPcChange($event, scope.row.btnArr, scope.row.id)"
                  >PC端{{ scope.row.secondMenuArr[0].isPC }}</el-checkbox
                >
              </span>
              <span v-if="!scope.row.secondMenuArr.length && scope.row.firstMenuArr.length">
                <el-checkbox
                  v-model="scope.row.firstMenuArr[0].isPC"
                  @change="handleCheckedPcChange($event, scope.row.btnArr, scope.row.id)"
                  >PC端{{ scope.row.firstMenuArr[0].isPC }}</el-checkbox
                >
              </span> -->
              <span v-if="scope.row.secondMenuArr.length">
                <el-checkbox
                  v-model="scope.row.secondMenuArr[0].isPC"
                  @change="handleCheckedPcChange($event, scope.row.btnArr, scope.row.id)"
                  >PC端</el-checkbox
                >
              </span>
              <span v-if="!scope.row.secondMenuArr.length && scope.row.firstMenuArr.length">
                <el-checkbox
                  v-model="scope.row.firstMenuArr[0].isPC"
                  @change="handleCheckedPcChange($event, scope.row.btnArr, scope.row.id)"
                  >PC端</el-checkbox
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column label="APP端权限" width="100">
            <template slot="header" slot-scope="scope">
              <el-checkbox
                :indeterminate="isAppIndeterminate"
                v-model="isAppCheckAll"
                @change="handleCheckAllChangeisApp($event, scope.row)"
                >全选</el-checkbox
              >
            </template>
            <template slot-scope="scope">
              <!-- <span v-if="scope.row.secondMenuArr.length">
                <el-checkbox
                  v-model="scope.row.secondMenuArr[0].isApp"
                  @change="handleCheckedAppChange($event, scope.row.btnArr, scope.row.id)"
                  >APP端{{ scope.row.secondMenuArr[0].isApp }}</el-checkbox
                >
              </span>
              <span v-if="!scope.row.secondMenuArr.length && scope.row.firstMenuArr.length">
                <el-checkbox
                  v-model="scope.row.firstMenuArr[0].isApp"
                  @change="handleCheckedAppChange($event, scope.row.btnArr, scope.row.id)"
                  >APP端{{ scope.row.firstMenuArr[0].isApp }}</el-checkbox
                >
              </span> -->
              <span v-if="scope.row.secondMenuArr.length">
                <el-checkbox
                  v-model="scope.row.secondMenuArr[0].isApp"
                  @change="handleCheckedAppChange($event, scope.row.btnArr, scope.row.id)"
                  >APP端</el-checkbox
                >
              </span>
              <span v-if="!scope.row.secondMenuArr.length && scope.row.firstMenuArr.length">
                <el-checkbox
                  v-model="scope.row.firstMenuArr[0].isApp"
                  @change="handleCheckedAppChange($event, scope.row.btnArr, scope.row.id)"
                  >APP端</el-checkbox
                >
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="btnArr" label="权限">
            <template slot-scope="scope">
              <el-checkbox-group
                v-model="checkedBtnArr"
                @change="handleCheckedBtnChange($event, scope.row.btnArr, scope.row.id)"
              >
                <el-checkbox v-for="(item, index) in scope.row.btnArr" :label="item.id" :key="item.id">{{
                  item.title
                }}</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <!-- <el-checkbox
                :indeterminate="scope.row.isIndeterminate"
                v-model="scope.row.checkAll"
                @change="handleCheckAllChange($event, scope.row.btnArr, scope.row.id)"
                >全选{{ scope.row.checkAll }}</el-checkbox
              > -->
              <el-checkbox
                :indeterminate="scope.row.isIndeterminate"
                v-model="scope.row.checkAll"
                @change="handleCheckAllChange($event, scope.row.btnArr, scope.row.id)"
                >全选</el-checkbox
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="item" style="background: none;">
        <el-row style="text-align: right;">
          <div class="dialog-footer">
            <el-button size="small" :loading="loading" class="title" @click="btnClose()">取消</el-button>
            <el-button size="small" type="primary" :loading="loading" class="title" @click="handleSubmit('form')"
              >保存</el-button
            >
          </div>
        </el-row>
      </div>
      <!-- 分页组件 -->
      <!-- <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination> -->
    </el-drawer>
  </div>
</template>
<script>
import templateTitle from '@/components/templateTitle.vue'
import Pagination from '@/components/Pagination'
// import treeMenuData from './treeMenuData/treeMenu'
// console.log(treeMenuData, 'treeMenuDatatreeMenuData')
import { _proTree } from '@/api/modular/supervision/projectMag/tab3'

import { sysRoleOwnMenuApplication } from '@/api/modular/system/tenantManage' //更改为公司拥有菜单接口
import { SysMenuTreeForGrant } from '@/api/modular/system/menuManage' //同步超管租户授权接
import { sysRoleGrantMenu } from '@/api/modular/system/roleManage'
export default {
  name: 'roleMenuDrawer',
  data() {
    return {
      drawer: false,
      roleEntity: {}, //角色实体

      tableData: [],
      newTabelData: [],
      checkedBtnArr: [],
      isCheckLevel3Children: false,
      firstMenuArr: [], // 一级级功能id存储
      checkedFirstArr: [], //一级菜单勾选提交
      secondMenuArr: [], // 二级功能id存储
      checkedSecondArr: [], //二级菜单勾选提交
      checked: false,

      isPCCheckAll: false,
      isAppCheckAll: false,
      isPCIndeterminate: false,
      isAppIndeterminate: false,
      isPcArr: [], // 初始化pc全新
      isAppArr: [], // 初始化app全新
      // checkAll: false,
      // checkedCities: ['上海', '北京'],
      // cities: cityOptions,
      isIndeterminate: false,
      roleData: [], //授权角色page
      roleVal: null, //授权角色数据

      treeArray: [], // 用于匹配id赋值isApp、isPC
      flatArray: [], // 用于匹配id赋值isApp、isPC

      tableData: [],
      statusEnum: {
        0: '待审核',
        1: '通过',
        2: '拒绝'
      },
      searchObj: {
        name: ''
      }, //搜索字段
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      loading: false //是显示加载
    }
  },
  computed: {
    checkAll: {
      get() {
        return this.newTabelData.every(item => item.checkAll)
      },
      set(value) {
        this.newTabelData.forEach(item => {
          item.checkAll = value
        })
      }
    }
  },
  components: {
    templateTitle,
    Pagination
  },
  created() {},
  mounted() {},
  methods: {
    //初始化方法
    async roleMenu(record) {
      console.log(record, 'record初始化方法')
      // this.formLoading = true
      // this.roleEntity = record
      // this.visible = true
      // await this.getMenuTree()
      // await this.expandedMenuKeys(record)
      this.roleEntity = record
      this.drawer = true
      this.loading = true
      await this.initTreeData(record)
    },
    // esc和点击旁白
    clearDrawerContent(done) {
      this.roleEntity = {}
      done()
    },
    // 取消或者是确认
    btnClose() {
      this.roleEntity = {}
      this.drawer = false
    },
    logCheckedArr() {
      console.log('+++++++选择', this.checkedBtnArr)
    },
    handleSubmit() {
      this.loading = true
      // 菜单权限obj
      let dealArr = []
      this.newTabelData.forEach(item => {
        if (item.secondMenuArr.length) {
          dealArr.push({
            id: item.secondMenuArr[0].id,
            isApp: item.secondMenuArr[0].isApp,
            isPC: item.secondMenuArr[0].isPC
          })
        } else if (!item.secondMenuArr.length && item.firstMenuArr.length) {
          dealArr.push({
            id: item.firstMenuArr[0].id,
            isApp: item.firstMenuArr[0].isApp,
            isPC: item.firstMenuArr[0].isPC
          })
        }
      })
      let menuArr = dealArr.filter(obj => this.checkedBtnArr.includes(obj.id)) //二级菜单

      console.log(menuArr, 'menuArr')
      let btnObj = this.filterDuplicates(menuArr, this.checkedBtnArr).map(a => {
        return {
          id: a,
          isApp: null,
          isPC: null
        }
      }) //par1 二级功能  par2 btn权限

      // console.log(dealArr, 'dealArr666666666666')
      console.log(btnObj, 'btnObj22222223333333333')
      let subData = [...menuArr, ...btnObj]
      console.log(this.checkedBtnArr, 'this.checkedBtnArr')
      console.log(subData, 'subDatasubData')
      sysRoleGrantMenu({
        id: this.roleEntity.id,
        grantMenuIdList: subData
      })
        .then(res => {
          if (res.success) {
            this.$message.success('授权成功')
            this.loading = false
            this.$emit('ok')
            this.btnClose()
          } else {
            this.$message.error('授权失败：' + res.message)
          }
        })
        .finally(res => {
          this.loading = false
        })
    },
    filterDuplicates(arr1, arr2) {
      let dealArr = arr1.map(ele => {
        return ele.id
      })
      const uniqueArray = arr2.filter(item => !dealArr.includes(item))
      return uniqueArray
    },
    //PC端全选
    handleCheckAllChangeisPC(value, row) {
      // console.log(value, 'PC端全选')
      this.newTabelData.forEach(item => {
        if (item.secondMenuArr.length) {
          item.secondMenuArr.forEach(item2 => {
            item2.isPC = value
          })
        } else if (!item.secondMenuArr.length && item.firstMenuArr.length) {
          item.firstMenuArr.forEach(item2 => {
            item2.isPC = value
          })
        }
      })
      // console.log(this.newTabelData, '777777')
      this.isPCCheckAll = value
      this.isPCIndeterminate = false
    },
    // pc端按钮
    handleCheckedPcChange(value, btnarr, id) {
      let dealArr = []
      this.newTabelData.forEach(item => {
        if (item.secondMenuArr.length) {
          if (item.secondMenuArr[0].isPC) {
            dealArr.push({
              isPC: item.secondMenuArr[0].isPC
            })
          }
        } else if (!item.secondMenuArr.length && item.firstMenuArr.length) {
          if (item.firstMenuArr[0].isPC) {
            dealArr.push({
              isPC: item.firstMenuArr[0].isPC
            })
          }
        }
      })
      // console.log('pc权限', dealArr)
      let checkedCount = dealArr.length
      this.isPCCheckAll = dealArr.length == this.isPcArr.length
      // console.log(this.isPCCheckAll, 'this.isPCCheckAll')
      this.isPCIndeterminate = checkedCount > 0 && checkedCount < this.isPcArr.length
    },
    // pcInitArr
    initPcArr(value, btnarr, id) {
      let dealArr = []
      this.newTabelData.forEach(item => {
        if (item.secondMenuArr.length) {
          dealArr.push({
            id: item.secondMenuArr[0].id,
            pid: item.secondMenuArr[0].parentId,
            title: item.secondMenuArr[0].title,
            isPC: item.secondMenuArr[0].isPC
          })
        } else if (!item.secondMenuArr.length && item.firstMenuArr.length) {
          dealArr.push({
            id: item.firstMenuArr[0].id,
            pid: item.firstMenuArr[0].parentId,
            title: item.firstMenuArr[0].title,
            isPC: item.firstMenuArr[0].isPC
          })
        }
      })
      // console.log('pc权限', dealArr)
      this.isPcArr = dealArr
    },
    //APP端全选
    handleCheckAllChangeisApp(value, row) {
      // console.log(value, 'app端全选')
      this.newTabelData.forEach(item => {
        if (item.secondMenuArr.length) {
          item.secondMenuArr.forEach(item2 => {
            item2.isApp = value
          })
        } else if (!item.secondMenuArr.length && item.firstMenuArr.length) {
          item.firstMenuArr.forEach(item2 => {
            item2.isApp = value
          })
        }
      })
      // console.log(this.newTabelData, '777777')
      this.isAppCheckAll = value
      this.isAppIndeterminate = false
    },
    // app端按钮
    handleCheckedAppChange(value, btnarr, id) {
      let dealArr = []
      this.newTabelData.forEach(item => {
        if (item.secondMenuArr.length) {
          if (item.secondMenuArr[0].isApp) {
            dealArr.push({
              isApp: item.secondMenuArr[0].isApp
            })
          }
        } else if (!item.secondMenuArr.length && item.firstMenuArr.length) {
          if (item.firstMenuArr[0].isApp) {
            dealArr.push({
              isApp: item.firstMenuArr[0].isApp
            })
          }
        }
      })
      // console.log('pc权限', dealArr)
      let checkedCount = dealArr.length
      this.isAppCheckAll = dealArr.length == this.isAppArr.length
      // console.log(this.isAppCheckAll, 'this.isAppCheckAll')
      this.isAppIndeterminate = checkedCount > 0 && checkedCount < this.isAppArr.length
    },
    // APPInitArr
    initAppArr(value, btnarr, id) {
      let dealArr = []
      this.newTabelData.forEach(item => {
        if (item.secondMenuArr.length) {
          dealArr.push({
            id: item.secondMenuArr[0].id,
            pid: item.secondMenuArr[0].parentId,
            title: item.secondMenuArr[0].title,
            isApp: item.secondMenuArr[0].isApp
          })
        } else if (!item.secondMenuArr.length && item.firstMenuArr.length) {
          dealArr.push({
            id: item.firstMenuArr[0].id,
            pid: item.firstMenuArr[0].parentId,
            title: item.firstMenuArr[0].title,
            isApp: item.firstMenuArr[0].isApp
          })
        }
      })
      // console.log('isApp权限', dealArr)
      this.isAppArr = dealArr
    },
    //  checkall
    handleCheckAllChange(value, btnarr, id) {
      // console.log(value, 'value44444')
      // this.checkedBtnArr = val ? cityOptions : []
      // this.isIndeterminate = false
      // console.log(id, 'let checkAll = this.checkIdsExist(value, btnarr)') //416275052470341
      // let checkAll = this.checkIdsExist(value, btnarr)

      this.newTabelData.forEach(item => {
        if (item.id == id) {
          // item.checkAll = checkAll
          item.isIndeterminate = false
        }
      })
      if (value) {
        let dealArr = btnarr.map(ele => {
          return ele.id
        })
        let nn = [...this.checkedBtnArr, ...dealArr]
        this.checkedBtnArr = [...new Set(nn)]
      } else {
        let nn = this.filteredArr(this.checkedBtnArr, btnarr)
        // console.log(nn, 'nn222222')
        this.checkedBtnArr = nn
      }
    },
    // btn check
    handleCheckedBtnChange(value, btnarr, id) {
      // let checkedCount = value.length
      // this.checkAll = checkedCount === this.cities.length
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
      // console.log(value, btnarr, 'value, btnarr')
      let checkedCount = this.findDuplicates(value, btnarr)
      let checkAll = this.checkIdsExist(value, btnarr)
      this.newTabelData.forEach(item => {
        if (item.id == id) {
          item.checkAll = checkAll
          item.isIndeterminate = checkedCount > 0 && checkedCount < btnarr.length
        }
      })
    },
    btnInitFormatter(row, column, cellValue, index) {
      // console.log(111111111, 'row')
    },
    // 过滤重复的id
    filteredArr(arr1, arr2) {
      let dealArr = arr2.map(ele => {
        return ele.id
      })
      return arr1.filter(id => !dealArr.includes(id))
    },
    // 检验是否存在
    checkIdsExist(arr1, arr2) {
      // console.log(arr1, 'arr1', arr2, 'arr2')
      // return arr1.every(id => arr2.some(item => item.id == id));
      let abc = arr2.map(ele => {
        return ele.id
      })
      return abc.every(id => arr1.includes(id))
    },
    // 获取重复的长度
    findDuplicates(array1, array2) {
      const result = array2.filter(item => array1.includes(item.id))
      return result.length
    },
    initTreeData(record) {
      SysMenuTreeForGrant({
        Application: 'manage'
      }).then(res => {
        if (res.success) {
          this.tableData = res.data
          this.treeArray = res.data
          console.log(this.tableData, 'this.tableData0000000')
          if (this.tableData.length) {
            // 树形存在再拉取拥有权限
            sysRoleOwnMenuApplication({
              id: record.id
            }).then(res => {
              if (res.success) {
                this.checkedBtnArr = res.data.length
                  ? res.data[0].menuIds.map(ele => {
                      return ele.id
                    })
                  : []
                this.flatArray = res.data.length ? res.data[0].menuIds : []
                // console.log(this.flatArray, 'this.flatArray')
                // 树形数据拉取完成之后再处理数据
                this.dealTableData()
              }
            })
          }
        }
      })
    },
    logCheckedArr() {
      console.log('+++++++选择', this.checkedBtnArr)
    },
    // // 分页插件事件
    // callFather(parm) {
    //   this.pageparm.currentPage = parm.currentPage
    //   this.pageparm.pageSize = parm.pageSize
    //   // this.getUserTenantRecord()
    // },
    dealTableData() {
      let StagArr = []
      let _this = this
      this.tableData.forEach(firstData => {
        // console.log(firstData, 'firstDatatopppppppppppppppppp')
        if (firstData.children.length > 0) {
          // console.log(firstData.children, 'firstData.children11111')
          let l1 = firstData.children.length
          // 判断二级菜单下的children是否存在
          if (firstData.children[0].type == 2) {
            let data = {
              firstName: firstData.title,
              firstMenuArr: [{ ...firstData }],
              secondName: '',
              // secondMenuArr: [],
              secondMenuArr: [{ ...this.matchArrayId(firstData, this.flatArray) }],
              btnArr: firstData.children,
              id: firstData.id,
              checkAll: false,
              isIndeterminate: false,
              deviceNum: 0, // 要合并的“一级菜单”
              oneNum: 0 // 仅有一级的情况
            }
            // console.log(data, '处理之后的每一项data')
            StagArr.push(data)
          }
          firstData.children.forEach((secondData, index) => {
            // console.log(secondData, 'secondData22222222')
            if (secondData.children.length) {
              let data = {
                firstName: firstData.title,
                firstMenuArr: [{ ...firstData }],
                secondName: secondData.title,
                // secondMenuArr: [{ ...secondData }],
                secondMenuArr: [{ ...this.matchArrayId(secondData, this.flatArray) }],
                btnArr: secondData.children.length ? secondData.children : [],
                id: secondData.id,
                checkAll: false,
                isIndeterminate: false,
                deviceNum: 0 // 要合并的“一级菜单”
              }
              if (index === 0) {
                data.deviceNum = l1 // 改变“一级菜单”数值 让每一个传感器的第一个对象的deviceNum有值 其他为0（比如图中第一个菜单deviceNum为4，下面三个均为0）
              } else {
                data.deviceNum = 0
              }
              // console.log(data, '处理之后的每一项data')
              StagArr.push(data)
            }
          })
        } else {
          {
            // console.log(firstData, '当第二级children为空时11111111111111111111111111111111111111111111111111111')
            let data = {
              firstName: firstData.title,
              firstMenuArr: [{ ...firstData }],
              secondName: '',
              // secondMenuArr: [],
              secondMenuArr: [{ ...this.matchArrayId(firstData, this.flatArray) }],
              btnArr: [],
              id: firstData.id,
              checkAll: false,
              isIndeterminate: false,
              deviceNum: 0, // 要合并的“一级菜单”
              oneNum: 0 // 仅有一级的情况
            }
            StagArr.push(data)
          }
        }
      })
      // console.log(StagArr, '_this.StagArrchulidoddddddddddddd')

      // this.newTabelData = StagArr
      this.newTabelData = StagArr
      this.btnCheckAll(this.newTabelData) // 回显全选
      console.log(this.newTabelData, 'this.newTabelData回显全选之后的3')
      this.initPcArr()
      this.initAppArr()
      this.handleCheckedPcChange()
      this.handleCheckedAppChange()
      this.loading = false
    },
    btnCheckAll(tableData) {
      tableData.forEach(item => {
        let checkedCount = this.findDuplicates(this.checkedBtnArr, item.btnArr)
        let checkAll = this.checkIdsExist(this.checkedBtnArr, item.btnArr)
        item.checkAll = checkAll
        item.isIndeterminate = checkedCount > 0 && checkedCount < item.btnArr.length
      })
    },
    deviceSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (row.deviceNum != 0) {
          return [row.deviceNum, 1]
        } else {
          // return [0, 0];
          if (row?.oneNum == 0) {
          } else {
            return [0, 0]
          }
        }
      }
    },
    checkLevel3Children(originArr) {
      // console.log(originArr, 'originArr')
      // 判断第一级下面的二级菜单数组or第三级权限数组的children长度
      if (originArr.children.length) {
        originArr.children.forEach(level2 => {
          // console.log(level2, 'level2')
          if (level2.children.length == 0) {
            this.isCheckLevel3Children = true
          }
          // level2.children.forEach(level3 =>{

          // })
        })
      }
    },
    // 匹配相同id
    matchArrayId(obj, array2) {
      // console.log(obj, array2, '匹配相同id')
      const matchedItem = array2.find(item2 => item2.id == obj.id)
      if (matchedItem) {
        obj.isApp = matchedItem.isApp
        obj.isPC = matchedItem.isPC
      }
      return obj
    },
    // 匹配两个数组id相同时，赋值isApp、isPC
    matchArrays(treeArray, flatArray) {
      // 遍历树形数组的每个子数组
      for (let i = 0; i < treeArray.length; i++) {
        const node = treeArray[i]

        // 查找与子数组id匹配的对象
        const matchedObject = flatArray.find(obj => obj.id === node.id)

        // 如果找到匹配的对象，则赋值给树形数组的isApp和isPC字段
        if (matchedObject) {
          node.isApp = matchedObject.isApp
          node.isPC = matchedObject.isPC
        }

        // 递归处理子数组的children字段
        if (node.children && node.children.length > 0) {
          matchArrays(node.children, flatArray)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';

::v-deep .el-form {
  padding: 20px;
}

::v-deep .custom-close-button {
  width: 50px;
  height: 50px;
  background-color: red;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
